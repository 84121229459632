.glyphicon {
	display:inline-block;
	vertical-align: middle;
	width:20px;
	height:20px;
	background-size:contain;

	&-calendar{
		background-image:url('../images/icn-edit.svg');
		&:hover {
			background-image:url('../images/icn-edit-hover.svg');
		}
	}
	&-chevron-left{
		background-image:url('../images/chevron-left.svg');

		&:hover {
			background-image:url('../images/chevron-left-hover.svg');
		}
	}
	&-chevron-right{
		background-image:url('../images/chevron-right.svg');

		&:hover {
			background-image:url('../images/chevron-right-hover.svg');
		}
	}

	&-remove {
		background-image:url('../images/icn-nav-close.svg');
		&:hover {
			background-image:url('../images/icn-nav-close-hover.svg');
		}
	}
}
